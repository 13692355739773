import React                                    from 'react';
import Img                                      from 'gatsby-image';
import { useStaticQuery, graphql }              from 'gatsby';
import { Center, Box, useMediaQuery, Heading } from '@chakra-ui/react';

import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Mounted from '@interness/web-core/src/components/structure/Mounted/Mounted';

import RolexLayout   from '../../../components/Layout';
import Checkboard    from '../../../components/Checkboard';
import Tracking      from '../../../components/Tracking';
import KeepExploring from '../../../components/KeepExploring';
import TwoColumn     from '../../../components/TwoColumn';

const RolexRaeumlichkeitenPage = () => {
  const data = useStaticQuery(query);
  const [portrait] = useMediaQuery('(max-width: 768px)');
  const name = data.directusCompany.display_name;

  const media = {
    banner: {
      portrait: data.bannerPortrait.childImageSharp.fluid,
      landscape: data.bannerLandscape.childImageSharp.fluid,
    },
    herzen: {
      portrait: data.herzenPortrait.childImageSharp.fluid,
      landscape: data.herzenLandscape.childImageSharp.fluid,
    },
    hohage: {
      portrait: data.hohagePortrait.childImageSharp.fluid,
      landscape: data.hohageLandscape.childImageSharp.fluid,
    },
    leidenschaft: {
      portrait: data.leidenschaftPortrait.childImageSharp.fluid,
      landscape: data.leidenschaftLandscape.childImageSharp.fluid,
    },
    momente: {
      portrait: data.momentePortrait.childImageSharp.fluid,
      landscape: data.momenteLandscape.childImageSharp.fluid,
    },
    zeit: {
      portrait: data.zeitPortrait.childImageSharp.fluid,
      landscape: data.zeitLandscape.childImageSharp.fluid,
    },
  }
  return (
    <RolexLayout>
      <Mounted>
        <Tracking pageType={'showroom page'}/>
        <section>
          <Img width="100%" height="25vh"
               fluid={portrait ? media.banner.portrait : media.banner.landscape}/>
        </section>
        <Spacer/>
        <section>
          <Center width="90%" margin="auto">
            <Box maxW="650px" w="100%" textAlign="center">
              <Box textTransform="uppercase">
                <p><b>{name} und Rolex</b></p>
                <Box sx={{
                  letterSpacing: '4.5px',
                  fontWeight: '300',
                  lineHeight: '1.2',
                }}>
                  <Heading as="h1" size='lg' sx={{ hyphens: 'auto' }}>UNSERE RÄUMLICHKEITEN</Heading>
                </Box>
              </Box>
              <p>
                Seit mehr als 120 Jahren ist Juwelier Hohage in Lüdenscheid, der Stadt des Lichtes,
                Anziehungsmagnet für exklusiven Schmuck und feinste Uhrmacherkunst.
              </p>
            </Box>
          </Center>
        </section>
        <Spacer/>
        <section>
          <Box width="90%" maxW="1140px" textAlign="center" margin="auto">
            <Img fluid={portrait ? media.hohage.portrait : media.hohage.landscape}/>
          </Box>
        </section>
        <Spacer/>
        <section>
          <Center maxW="650px" width="90%" margin="auto">
            <p>
              Juwelier Hohage ist stolz, Teil des weltweiten Netzwerks offizieller Rolex Fachhändler zu sein und schafft
              Ihnen somit ein exzellentes Markenerlebnis in unserem Geschäft.
            </p>
          </Center>
        </section>
        <Spacer/>
        <section>
          <TwoColumn title={'Rolex aus Leidenschaft'} imageSet={media.leidenschaft}>
            Juwelier Hohage ist stolz, Teil des weltweiten Netzwerks offizieller Rolex Fachhändler zu sein und schafft
            Ihnen somit ein exzellentes Markenerlebnis in unserem Geschäft.
          </TwoColumn>
          <Checkboard title={'Juwelier Hohage im Herzen von Lüdenscheid'} imageSet={media.herzen}>
            Zentral gelegen in der schönen Fußgängerzone von Lüdenscheid, lädt unser modernes Geschäft mit den vielen
            Schaufenster zu einem intensiven Einkaufserlebnis ein.
          </Checkboard>
          <Checkboard title={'Vom Streben, der Zeit ein Gesicht zu geben.'} imageSet={media.momente} inverted>
            Eine Rolex ist unverwechselbar in ihrem
            Äußeren als auch in ihrem Inneren.
            Ein Blick genügt, um zu erkennen, für welches Modell Sie sich entscheiden und welch meisterhafte Technik sie
            am Handgelenk tragen.
            Wir können die Zeit nicht anhalten, doch die schönsten Momente mit einer Rolex erleben.
          </Checkboard>
          <Checkboard title={'Bewegende Momente'} imageSet={media.zeit}>
            Wir laden Sie herzlich ein, bei Ihrem Besuch in unserem Geschäft die einzigartige Welt der Rolex Uhren zu
            erleben, bewegende Momente zu erfahren und sich von unserem Team in entspannter Atmosphäre mit viel
            Einfühlungsvermögen beraten zu lassen.
          </Checkboard>
        </section>
        <Spacer/>
        <KeepExploring/>
        <Spacer/>
      </Mounted>
    </RolexLayout>
  )
};

export default RolexRaeumlichkeitenPage;

const query = graphql`
    query {
        directusCompany {
            display_name
        }
        bannerLandscape: file(name: {eq: "banner_landscape_01"} relativeDirectory: {eq: "landscape"}) {
            name
            childImageSharp {
                fluid(quality: 100, maxWidth: 2880) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bannerPortrait: file(name: {eq: "banner_portrait_01"} relativeDirectory: {eq: "portrait"}) {
            name
            childImageSharp {
                fluid(quality: 100, maxWidth: 750) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        herzenLandscape: file(name: {eq: "herzen"} relativeDirectory: {eq: "landscape/rooms"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        herzenPortrait: file(name: {eq: "herzen"} relativeDirectory: {eq: "portrait/rooms"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hohageLandscape: file(name: {eq: "hohage"} relativeDirectory: {eq: "landscape/rooms"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hohagePortrait: file(name: {eq: "hohage"} relativeDirectory: {eq: "portrait/rooms"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        leidenschaftLandscape: file(name: {eq: "leidenschaft"} relativeDirectory: {eq: "landscape/rooms"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        leidenschaftPortrait: file(name: {eq: "leidenschaft"} relativeDirectory: {eq: "portrait/rooms"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        momenteLandscape: file(name: {eq: "momente"} relativeDirectory: {eq: "landscape/rooms"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        momentePortrait: file(name: {eq: "momente"} relativeDirectory: {eq: "portrait/rooms"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        zeitLandscape: file(name: {eq: "zeit"} relativeDirectory: {eq: "landscape/rooms"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        zeitPortrait: file(name: {eq: "zeit"} relativeDirectory: {eq: "portrait/rooms"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;